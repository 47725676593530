/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
import React from 'react';
// import _get from 'lodash/get';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Location } from '@reach/router';
import { ChevronLeft } from 'react-feather';
import dayjs from 'dayjs';
import qs from 'qs';

// import convert from 'html-to-text';
import ShareButtons from '../components/Share';

import RichContent from '../components/Content';
import Layout from '../components/Layout';
// import SEO from '../components/PageSeo';

const { convert } = require('html-to-text');

const SinglePostTemplate = ({
  Title,
  Post_Date,
  Content,
  extraComponent,
  nextPostURL,
  prevPostURL
}) => (
  <main>
    <article
      className="SinglePost section light"
      itemScope
      itemType="http://schema.org/BlogPosting"
    >
      <div className="container skinny">
        <Link className="SinglePost--BackButton" to="/articles-page/">
          <ChevronLeft /> BACK
        </Link>
        <div className="SinglePost--Content relative">
          <div className="SinglePost--Meta">
            {Post_Date && (
              <div
                style={{
                  fontSize: 23,
                  fontWeight: 'bold',
                  padding: 4,
                  marginLeft: 8,
                  color: '#606a2b'
                }}
              >
                {dayjs(Post_Date).format('dddd, MMMM D YYYY')}
              </div>
            )}
          </div>

          {Title && (
            <h1 className="SinglePost--Title" itemProp="title">
              {Title}
            </h1>
          )}

          <div className="SinglePost--InnerContent">
            <RichContent source={Content || <div />} />
          </div>

          <div className="SinglePost--Pagination">
            {prevPostURL && (
              <Link
                className="SinglePost--Pagination--Link prev"
                to={prevPostURL}
              >
                Previous Post
              </Link>
            )}
            {nextPostURL && (
              <Link
                className="SinglePost--Pagination--Link next"
                to={nextPostURL}
              >
                Next Post
              </Link>
            )}
          </div>
          {extraComponent}
        </div>
      </div>
    </article>
  </main>
);

// Export Default SinglePost for front-end
const SinglePost = () => {
  const data = useStaticQuery(pageQuery);
  const { News_Item } = data.directus;
  return (
    <Location>
      {({ location }) => {
        let queryObj = location.search.replace('?', '');
        queryObj = qs.parse(queryObj);
        if (!queryObj.id) {
          return <div />;
        }
        const p = News_Item.findIndex(i => i.id === queryObj.id);
        const post = News_Item[p];
        const title = `Read ${post.Title} `;
        const url = location.href;
        let nextUrl = null;
        let prevUrl = null;
        const twitterHandle = '_MsLinda';
        if (p < News_Item.length - 1) {
          nextUrl = `/single-post?id=${News_Item[p + 1].id}`;
        }
        if (p > 0) {
          prevUrl = `/single-post?id=${News_Item[p - 1].id}`;
        }
        let seoImageUrl = post.Feature_Image ? post.Feature_Image.id : null;
        if (!seoImageUrl) {
          seoImageUrl = post?.Default_Seo.Share_Image
            ? post.Default_Seo.Share_Image.id
            : null;
        }
        let description;
        if (post.Excerpt) {
          description = convert(post.Excerpt, {
            wordwrap: 130
          });
        } else {
          description = post.Default_Seo?.Meta_Description || '';
        }
        const ptitle = post.Title || post.Default_Seo?.Meta_Title || '';

        // const { tags } = post.frontmatter;
        return (
          <Layout
            meta={{
              Default_Seo: {
                Meta_Description: description,
                Meta_Title: ptitle,
                Share_Image: { id: seoImageUrl }
              }
            }}
          >
            {/* <SEO
              title={post.Title || post.Default_Seo?.Meta_Title || ''}
              description={description}
              url={location.href}
              image={seoImageUrl}
              article={true}
            /> */}
            <SinglePostTemplate
              {...post}
              extraComponent={
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: '500',
                    marginTop: '64px',
                    color: 'black',
                    width: '100%'
                  }}
                >
                  <span>SHARE THIS NEWS</span>
                  <ShareButtons
                    title={title}
                    url={url}
                    twitterHandle={twitterHandle}
                    // tags={tags}
                  />
                </div>
              }
              nextPostURL={nextUrl}
              prevPostURL={prevUrl}
            />
          </Layout>
        );
      }}
    </Location>
  );
};

export default SinglePost;

const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query singlePost {
    directus {
      News_Item {
        Default_Seo {
          Meta_Title
          Meta_Description
          Share_Image {
            id
          }
        }
        Title
        Excerpt
        Content
        Feature_Image {
          id
        }
        id
        Post_Date
      }
    }
  }
`;
